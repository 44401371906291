import { createApp } from 'vue';
import { storage } from './config/storage';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VCalendar from 'v-calendar';
import useAuth from './use/useAuth';
import useAxios from './use/useAxios';
import moment from 'moment';
import Maska from 'maska';
import '@suadelabs/vue3-multiselect/dist/vue3-multiselect.css';
import AppUtils from './AppUtils';
import { API_URL } from './config/config';

declare global {
    interface Window {
        jQuery: any | null;
    }
}

const { setToken } = useAuth();
const { setAuthorization } = useAxios();

const app = createApp(App);

moment.locale('es')

app.directive('uppercase', {
    updated(el) {
        el.value = el.value.toUpperCase();
        el.dispatchEvent(new Event('input'));
    },
});
app.directive('userCan', {
    mounted:async(el, binding) => {
        const [padre, permiso] = binding.value
        if(!AppUtils.userCan(padre, permiso)){
            el.remove()
        }
    },
});


storage.getItem('token').then((token) => {
    setToken(token).then(() => {
        app.use(router);
        app.use(VCalendar, {});
        app.use(VueAxios, axios);
        app.use(Maska);
        setAuthorization(token);
        return router.isReady();
    });

    router.isReady().then(() => {
        app.mount('#app');
    });
});

app.config.globalProperties.$utils = AppUtils;

const filters = {
    time(seconds: any) {
        if (!seconds) return;
        const formatted = moment.utc(seconds).format('mm:ss');
        return formatted;
    },
    timeString(secs: any) {
        if (!secs) return;
        const minutes = moment.utc(secs).format('mm');
        const seconds = moment.utc(secs).format('ss');
        return `${Number(minutes) > 0 ? 'minutes' : ''} ${seconds} seconds`;
    },
    datetime(strDate: any) {
        if (!strDate) return;
        const formatted = moment(strDate).format('DD/MM/yy hh:mm a');
        return formatted;
    },
    timeFormatted(strHour: any) {
        if (!strHour) return;
        const formatted = moment(strHour, 'HH:mm:ss').format('hh:mm a');
        return formatted;
    },
    year(strDate: any) {
        if (!strDate) return;
        const formatted = moment(strDate).format('yy');
        return formatted;
    },
    month(strDate: any) {
        if (!strDate) return;
        const formatted = moment(strDate).format('MM');
        return formatted;
    },
    day(strDate: any) {
        if (!strDate) return;
        const formatted = moment(strDate).format('DD');
        return formatted;
    },
    date(strDate: any) {
        if (!strDate) return;
        const formatted = moment(strDate).format('DD/MM/yy');
        return formatted;
    },
    id(id: any) {
        if (!id) return;
        return id.split('-')[0];
    },
    currency(ammount: any) {
        if (!ammount) {
            return new Intl.NumberFormat('es-MX', {
                style: 'currency',
                currency: 'MXN',
            }).format(0);
        }
        if (typeof ammount === 'string') ammount = ammount.replace('$', '');
        return new Intl.NumberFormat('es-MX', {
            style: 'currency',
            currency: 'MXN',
        }).format(ammount);
    },
    applyTax(monto: number, tax: number) {
        let r = monto;
        if (tax && tax > 0) {
            if(tax >= 100){
                tax = 1;
            } else {
                tax = (tax / 100) + 1;
            }
            r = monto * tax;
        }    
        return r;    
    },
    tax(monto: number, tax: number) {
        let r = monto;
        if (tax && tax > 0) {
            if(tax >= 100){
                tax = 1;
            } else {
                tax = (tax / 100);
            }
            r = monto * tax;
        }    
        return r;    
    },
    float(number: any) {
        if (number) {
            return parseFloat(number).toFixed(2);
        } else {
            return 0.00;
        }
    },
    capitalLetters(...words: String[]) {
        let capitalize = '';
        words.forEach((el) => {
            capitalize += el.charAt(0);
        });
        return capitalize.toUpperCase();
    },
    percent(value: any) {
        if (value > 1) {
            return '100%';
        } else {
            return `${value * 100}%`;
        }
    },
    percentNotConvertion(value: any) {
        return `${(Math.round(value * 100) / 100).toFixed(2)}%`;        
    },
    nameOrSocialReason(socio: any, showId: boolean = false) {
        if (!socio) return '';
        const {
            id_socio,
            nombre_razon_social,
            razon_social,
            nombre,
            apellido_paterno,
            apellido_materno,
        } = socio;
        const complement = showId ? `- ${id_socio}` : '';
        if (razon_social == 'FISICA') {
            return `${nombre} ${apellido_paterno} ${apellido_materno} ${complement}`;
        } else if (razon_social == 'MORAL')
            return `${nombre_razon_social} ${complement}`;
        return '';
    },
    imageUrl(id_archivo: string) {
        return `${API_URL}files/file?load=${id_archivo}`;
    },
    concatWs(separator:string, ...args: []) {
        return args
            .filter(arg => arg != null)
            .join(separator)
    }
};

//Filters
app.config.globalProperties.$filters = filters

export { filters }
